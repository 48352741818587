<template>
  <section>
    <b-form>
      <!-- estado -->
      <div class="grid grid-cols-1 md:grid-cols-12 gap-4">
        <div class="col-span-8 border-solid border-b border-gray-300 pb-2">
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12 sm:col-span-3">
              <Dropdown
                :Items="dropdownItemsEstados"
                :label="$t('sectionNcm.Create.Uf')"
                @ItemSelectId="DropdownSelected($event, 'UfOrigem')"
              >
              </Dropdown>
            </div>
            <div class="col-span-12 sm:col-span-9">
              <input-default
                v-model="viewModel.ufOrigem"
                :label="$t('sectionNcm.Create.StateOrigin')"
                disabled="true"
              />
            </div>
            <div class="col-span-12 sm:col-span-3">
              <Dropdown
                :Items="dropdownItemsEstados"
                :label="$t('sectionNcm.Create.Uf')"
                @ItemSelectId="DropdownSelected($event, 'UfDestino')"
              >
              </Dropdown>
            </div>
            <div class="col-span-12 sm:col-span-9">
              <input-default
                v-model="viewModel.ufDestino"
                :label="$t('sectionNcm.Create.StateDestination')"
                disabled="true"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- outras informações -->
      <div class="grid grid-cols-1 md:grid-cols-12 gap-4 mt-3">
        <div class="col-span-8 border-solid border-b border-gray-300">
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12 xl:col-span-6">
              <Dropdown
                :Items="dropdownItemsCst"
                :label="$t('sectionNcm.Create.Cst')"
                @ItemSelectId="DropdownSelected($event, 'Cst')"
              >
              </Dropdown>
            </div>
            <div class="col-span-12 xl:col-span-6">
              <Dropdown
                :Items="dropdownItemsCsosn"
                :label="$t('sectionNcm.Create.Csosn')"
                @ItemSelectId="DropdownSelected($event, 'Csosn')"
              >
              </Dropdown>
            </div>
          </div>
        </div>
        <div class="col-span-8"></div>
      </div>
      <!-- obrigatorios -->
      <div class="grid grid-cols-1 md:grid-cols-12 gap-4">
        <div class="col-span-8 border-solid border-b border-gray-300">
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12 lg:col-span-3">
              <input-default
                v-model="viewModel.icmsPercentage"
                :rules="{ required: true, decimal: [24, 5], positive: true }"
                :label="$t('sectionNcm.Create.IcmsPercentage')"
                type="number"
                max="24"
              />
            </div>
            <div class="col-span-12 lg:col-span-3">
              <input-default
                v-model="viewModel.internacionalIcmsPercentage"
                :rules="{ required: true, decimal: [24, 5], positive: true }"
                :label="$t('sectionNcm.Create.InternationalIcms')"
                type="number"
                max="24"
              />
            </div>
            <div class="col-span-12 lg:col-span-3">
              <input-default
                v-model="viewModel.mvaPercentage"
                :rules="{ required: true, decimal: [10, 2], positive: true }"
                :label="$t('sectionNcm.Create.MvaPer')"
                type="number"
                max="10"
              />
            </div>
            <div class="col-span-12 lg:col-span-3">
              <input-default
                v-model="viewModel.fcpPercentage"
                :rules="{ required: true, decimal: [24, 5], positive: true }"
                :label="$t('sectionNcm.Create.FcpPercent')"
                type="number"
                max="24"
              />
            </div>
          </div>
        </div>
      </div>
    </b-form>
  </section>
</template>

<script type="module">
import { required } from "@validations";
import InputDefault from "@core/components/commons/inputs/InputDefault";
import { BForm } from "bootstrap-vue";

import Dropdown from "@/@core/components/commons/selectDropdown/Dropdown.vue";

export default {
  name: "SupplementaryInformationSupplier",
  components: {
    BForm,
    InputDefault,
    Dropdown,
  },
  data() {
    return {
      modalIsOpen: false,
      dropdownItemsCst: [],
      dropdownItemsCsosn: [],
      dropdownItemsEstados: [],
      viewModel: {
        ufOrigemId: null,
        ufOrigem: null,
        ufDestinoId: null,
        ufDestino: null,
        cstId: null,
        cst: null,
        csosn: null,
        csosnId: null,
        icmsPercentage: null,
        internacionalIcmsPercentage: null,
        mvaPercentage: null,
        fcpPercentage: null,
      },
      required,
    };
  },
  created() {
    this.ObterSeletorEstados();
    this.ObterSeletorTributosCst();
    this.ObterSeletorTributosCsosn();
  },

  methods: {
    ObterSeletorTributosCst() {
      this.$http({
        url: `/tributo/obter-seletor-tributos-tipo/${0}`,
        method: "GET",
      }).then((res) => {
        this.dropdownItemsCst = res.data;
      });
    },
    ObterSeletorTributosCsosn() {
      this.$http({
        url: `/tributo/obter-seletor-tributos-tipo/${1}`,
        method: "GET",
      }).then((res) => {
        this.dropdownItemsCsosn = res.data;
      });
    },
    ObterSeletorEstados() {
      this.$http({
        url: `/estado/obter-seletor-estados`,
        method: "GET",
      }).then((res) => {
        this.dropdownItemsEstados = res.data;
      });
    },
    BuscaNomeEstado(id, campo) {
      this.$http({
        url: `/estado/obter/${id}`,
        method: "GET",
      }).then((res) => {
        if (campo == "UfOrigem") {
          this.viewModel.ufOrigem = res.data.nome;
        } else if (campo == "UfDestino") {
          this.viewModel.ufDestino = res.data.nome;
        }
      });
    },
    DropdownSelected(item, tipo) {
      if (tipo == "UfOrigem") {
        this.viewModel.ufOrigemId = item;
        this.BuscaNomeEstado(this.viewModel.ufOrigemId, "UfOrigem");
      } else if (tipo == "UfDestino") {
        this.viewModel.ufDestinoId = item;
        this.BuscaNomeEstado(this.viewModel.ufDestinoId, "UfDestino");
      } else if (tipo == "Cst") {
        this.viewModel.cstId = item;
        this.viewModel.cst = item.descricao;
      } else if (tipo == "Csosn") {
        this.viewModel.csosnId = item;
        this.viewModel.csosn = item.descricao;
      }
    },
  },
  watch: {
    // whenever question changes, this function will run
    viewModel: {
      handler(newValue) {
        this.$emit("complemento", newValue);
      },
      deep: true,
    },
  },
};
</script>
