<template>
  <section class="w-full">
    <header-register :showButtonView="true"
      >{{ $t("sectionNcm.titleNcm") }}
    </header-register>
    <main class="w-full">
      <ManageNcmCreate />
    </main>
  </section>
</template>

<script type="module">
import HeaderRegister from "@core/components/register/HeaderRegister.vue";
import ManageNcmCreate from "@/components/ncm/components/create/ManageNcmCreate.vue";

export default {
  name: "ProvidersEdit",
  components: {
    HeaderRegister,
    ManageNcmCreate,
  },
};
</script>
