<template>
  <section>
    <b-form>
      <!-- NCM -->
      <div class="grid grid-cols-1 md:grid-cols-12 gap-4">
        <div class="col-span-8">
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12 sm:col-span-6">
              <input-required
                v-model="viewModel.ncm"
                :label="$t('sectionNcm.Create.Ncm')"
                :rules="{ required: true, max: 10 }"
                max="10"
              />
            </div>
            <div class="col-span-12 lg:col-span-6">
              <CheckboxDefault
                label="NCM de serviço"
                v-model="viewModel.ncmService"
              />
            </div>
          </div>
        </div>

        <!-- DESCRIÇÃO -->
        <div class="col-span-8 border-solid border-b border-gray-300 pb-2">
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12 sm:col-span-12">
              <input-required
                v-model="viewModel.description"
                :label="$t('sectionNcm.Create.Description')"
                :rules="{ required: true, max: 50 }"
                max="50"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- Resto -->
      <div class="grid grid-cols-1 md:grid-cols-12 gap-4 mt-3">
        <div class="col-span-8 border-solid border-b border-gray-300">
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12 xl:col-span-3">
              <input-default
                v-model="viewModel.mvaPercent"
                :label="$t('sectionNcm.Create.MvaPercent')"
                :rules="{ decimal: [10, 2], positive: true }"
                type="number"
                max="10"
              />
            </div>
            <div class="col-span-12 xl:col-span-3">
              <input-default
                v-model="viewModel.exCode"
                :label="$t('sectionNcm.Create.Excode')"
                type="number"
              />
            </div>
            <div class="col-span-12 xl:col-span-3">
              <input-default
                v-model="viewModel.nationalAliq"
                :rules="{ decimal: [10, 2], positive: true }"
                :label="$t('sectionNcm.Create.NationalAliq')"
                type="number"
                max="10"
              />
            </div>
            <div class="col-span-12 xl:col-span-3">
              <input-default
                v-model="viewModel.importAliq"
                :rules="{ decimal: [10, 2], positive: true }"
                :label="$t('sectionNcm.Create.ImportAliq')"
                type="number"
                max="10"
              />
            </div>
            <div class="col-span-12 lg:col-span-6">
              <input-default
                v-model="viewModel.CodTributoPisEntrada"
                :rules="{ decimal: [24, 5], positive: true }"
                :label="$t('sectionNcm.Create.PisEntrance')"
                @onClickIcon="
                  ObterPisCofins(viewModel.CodTributoPisEntrada, 'PisEntrada')
                "
                :hasIcon="true"
                max="24"
              />
            </div>
            <div class="col-span-12 lg:col-span-6">
              <Dropdown
                v-model="viewModel.pisEntrance"
                :Items="dropdownItemsPisCofins"
                :label="$t('sectionNcm.Create.Description')"
                @ItemSelectId="DropdownSelected($event, 'PisEntrada')"
              >
              </Dropdown>
            </div>
            <div class="col-span-12 lg:col-span-6">
              <input-default
                v-model="viewModel.CodTributoPisSaida"
                :rules="{ decimal: [24, 5], positive: true }"
                :label="$t('sectionNcm.Create.PisExit')"
                @onClickIcon="
                  ObterPisCofins(viewModel.CodTributoPisSaida, 'PisSaida')
                "
                :hasIcon="true"
                max="24"
              />
            </div>
            <div class="col-span-12 lg:col-span-6">
              <Dropdown
                v-model="viewModel.pisExit"
                :Items="dropdownItemsPisCofins"
                :label="$t('sectionNcm.Create.Description')"
                @ItemSelectId="DropdownSelected($event, 'PisSaida')"
              >
              </Dropdown>
            </div>
            <div class="col-span-12 lg:col-span-6">
              <input-default
                v-model="viewModel.CodTributoCofinsEntrada"
                :rules="{ decimal: [24, 5], positive: true }"
                :label="$t('sectionNcm.Create.CofinsEntrance')"
                @onClickIcon="
                  ObterPisCofins(
                    viewModel.CodTributoCofinsEntrada,
                    'CofinsEntrada'
                  )
                "
                :hasIcon="true"
                max="24"
              />
            </div>
            <div class="col-span-12 lg:col-span-6">
              <Dropdown
                v-model="viewModel.cofinsEntrance"
                :Items="dropdownItemsPisCofins"
                :label="$t('sectionNcm.Create.Description')"
                @ItemSelectId="DropdownSelected($event, 'CofinsEntrada')"
              >
              </Dropdown>
            </div>
            <div class="col-span-12 lg:col-span-6">
              <input-default
                v-model="viewModel.CodTributoCofinsSaida"
                :rules="{ decimal: [24, 5], positive: true }"
                :label="$t('sectionNcm.Create.CofinsExit')"
                @onClickIcon="
                  ObterPisCofins(viewModel.CodTributoCofinsSaida, 'CofinsSaida')
                "
                :hasIcon="true"
                max="24"
              />
            </div>
            <div class="col-span-12 lg:col-span-6">
              <Dropdown
                v-model="viewModel.cofinsExit"
                :Items="dropdownItemsPisCofins"
                :label="$t('sectionNcm.Create.Description')"
                @ItemSelectId="DropdownSelected($event, 'CofinsSaida')"
              >
              </Dropdown>
            </div>
          </div>
        </div>
        <div class="col-span-8"></div>
      </div>
      <!-- aliquota -->
      <div class="grid grid-cols-1 md:grid-cols-12 gap-4">
        <div class="col-span-8 border-solid border-b border-gray-300">
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12 lg:col-span-4">
              <input-default
                v-model="viewModel.aliqPis"
                :label="$t('sectionNcm.Create.AliqPis')"
                :rules="{ decimal: [24, 5], positive: true }"
                type="number"
                max="24"
              />
            </div>
            <div class="col-span-12 lg:col-span-4 gap-4">
              <input-default
                v-model="viewModel.aliqCofins"
                :label="$t('sectionNcm.Create.AliqCofins')"
                :rules="{ decimal: [24, 5], positive: true }"
                type="number"
                max="24"
              />
            </div>
          </div>
        </div>
      </div>
    </b-form>
  </section>
</template>

<script>
import { required } from "@validations";
import InputRequired from "@core/components/commons/inputs/InputRequired";
import InputDefault from "@core/components/commons/inputs/InputDefault";
import CheckboxDefault from "@core/components/commons/checkbox/CheckboxDefault";
import Dropdown from "@/@core/components/commons/selectDropdown/Dropdown.vue";
import { BForm } from "bootstrap-vue";

export default {
  components: {
    BForm,
    InputRequired,
    InputDefault,
    CheckboxDefault,
    Dropdown,
  },
  data() {
    return {
      dropdownItemsPisCofins: [],
      viewModel: {
        ncm: null,
        ncmService: false,
        description: null,
        mvaPercent: null,
        exCode: null,
        nationalAliq: null,
        importAliq: null,
        pisEntrance: null,
        CodTributoPisEntrada: null,
        pisExit: null,
        CodTributoPisSaida: null,
        cofinsEntrance: null,
        CodTributoCofinsEntrada: null,
        cofinsExit: null,
        CodTributoCofinsSaida: null,
        aliqPis: null,
        aliqCofins: null,
      },
      required,
    };
  },
  created() {
    this.ObterSeletorPisCofins();
  },
  methods: {
    ObterSeletorPisCofins() {
      this.$http({
        url: `/tributo/obter-seletor-tributos-tipo/${4}`,
        method: "GET",
      }).then((res) => {
        this.dropdownItemsPisCofins = res.data;
      });
    },
    ObterPisCofins(codigoTributo, campo) {
      if (codigoTributo == "" || codigoTributo == null) {
        if (campo == "PisEntrada") {
          this.viewModel.pisEntrance = null;
        }
        if (campo == "PisSaida") {
          this.viewModel.pisExit = null;
        }
        if (campo == "CofinsEntrada") {
          this.viewModel.cofinsEntrance = null;
        }
        if (campo == "CofinsSaida") {
          this.viewModel.cofinsExit = null;
        }
        return;
      }
      this.$http({
        url: `/tributo/obter-PisCofins-por-codigo-tributo/${codigoTributo}`,
        method: "GET",
      }).then((res) => {
        if (campo == "PisEntrada") {
          this.viewModel.pisEntrance = res.data.id;
        }
        if (campo == "PisSaida") {
          this.viewModel.pisExit = res.data.id;
        }
        if (campo == "CofinsEntrada") {
          this.viewModel.cofinsEntrance = res.data.id;
        }
        if (campo == "CofinsSaida") {
          this.viewModel.cofinsExit = res.data.id;
        }
      });
    },
    BuscaCodTributo(item, tipo) {
      this.$http({
        url: `/tributo/obter/${item}`,
        method: "GET",
      }).then((res) => {
        if (tipo == "PisEntrada") {
          this.viewModel.CodTributoPisEntrada = res.data.codigoTributo;
        } else if (tipo == "PisSaida") {
          this.viewModel.CodTributoPisSaida = res.data.codigoTributo;
        } else if (tipo == "CofinsEntrada") {
          this.viewModel.CodTributoCofinsEntrada = res.data.codigoTributo;
        } else if (tipo == "CofinsSaida") {
          this.viewModel.CodTributoCofinsSaida = res.data.codigoTributo;
        }
      });
    },
    DropdownSelected(item, tipo) {
      if (tipo == "PisEntrada") {
        this.viewModel.pisEntrance = item;
        this.BuscaCodTributo(this.viewModel.pisEntrance, tipo);
      } else if (tipo == "PisSaida") {
        this.viewModel.pisExit = item;
        this.BuscaCodTributo(this.viewModel.pisExit, tipo);
      } else if (tipo == "CofinsEntrada") {
        this.viewModel.cofinsEntrance = item;
        this.BuscaCodTributo(this.viewModel.cofinsEntrance, tipo);
      } else if (tipo == "CofinsSaida") {
        this.viewModel.cofinsExit = item;
        this.BuscaCodTributo(this.viewModel.cofinsExit, tipo);
      }
    },
  },
  watch: {
    viewModel: {
      handler(newValue) {
        this.$emit("geral", newValue);
      },
      deep: true,
    },
  },
};
</script>
