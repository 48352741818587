<template>
  <div class="w-full flex flex-col justify-between">
    <section class="-mt-4">
      <b-tabs class="w-full">
        <b-tab title="Geral">
          <GeneralNcmInformation @geral="InformacoesGerais" class="mt-4" />
        </b-tab>
        <b-tab title="Complemento">
          <SupplementaryInformationNcm
            @complemento="InformacoesComplementares"
            class="mt-4"
          />
        </b-tab>
      </b-tabs>
    </section>
    <div class="flex">
      <span class="text-red-600"
        >Os campos com * são de preenchimento obrigatório</span
      >
    </div>
    <footer class="w-full flex items-center justify-start gap-5 my-10">
      <Button
        class="bg-green-500 shadow shadow-[rgba(200, 200, 200, 0.7)] hover:bg-green-600 transition-all duration-300"
        buttonText="Confirmar"
        :onClick="CreateSupplier"
      />
      <Button
        class="bg-red-500 shadow shadow-[rgba(200, 200, 200, 0.7)] hover:bg-red-600 transition-all duration-300"
        buttonText="Cancelar"
        :onClick="redirectUrl"
      />
    </footer>
    <Modal
      v-if="modalIsOpen"
      :modalIsOpen.sync="modalIsOpen"
      :closeModal="closeModal"
      :modalText="$t('commons.modalTextCreate')"
    />

    <Modal
      v-if="modalError"
      :modalIsOpen.sync="modalError"
      :closeModal="closeModal"
      :modalText="$t('commons.modalErrorCreate')"
    />
    <Modal
      v-if="modalErroSalvar"
      :modalIsOpen.sync="modalErroSalvar"
      :closeModal="closeModal"
      :modalText="$t('commons.modalErroSalvar')"
    />
  </div>
</template>

<script>
import { BTabs, BTab } from "bootstrap-vue";

import GeneralNcmInformation from "./GeneralNcmInformation";
import SupplementaryInformationNcm from "./SupplementaryInformationNcm";
import Button from "@core/components/commons/button/Button";
import Modal from "@core/components/commons/modal/Modal";

export default {
  name: "ManageNcmCreate",
  components: {
    BTabs,
    BTab,
    GeneralNcmInformation,
    SupplementaryInformationNcm,
    Button,
    Modal,
  },
  data() {
    return {
      modalIsOpen: false,
      modalErroSalvar: false,
      modalError: false,
      viewModel: {
        contaId: "3c5d1b6c-c0c3-4774-a307-992c25c2bda2",
        integracaoId: "string",
        codigoNcm: null,
        descricao: null,
        ncmServico: null,
        percentualMva: null,
        codigoNcmEx: null,
        aliquotaNacional: null,
        aliquotaImportacao: null,
        cstPisEntradaId: null,
        cstPisSaidaId: null,
        cstCofinsEntradaId: null,
        cstCofinsSaidaId: null,
        aliquotaPis: null,
        aliquotaCofins: null,
        Estados: [],
      },
    };
  },
  created() {},
  methods: {
    redirectUrl() {
      return this.$router.push("/ncm");
    },
    openModal() {
      this.modalIsOpen = true;
    },
    closeModal(event) {
      if (event.target === event.currentTarget) {
        this.modalIsOpen = false;
        this.modalError = false;
        this.modalErroSalvar = false;
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    },
    InformacoesGerais(view) {
      //geral
      (this.viewModel.codigoNcm = view.ncm),
        (this.viewModel.descricao = view.description),
        (this.viewModel.ncmServico = view.ncmService),
        (this.viewModel.percentualMva = view.mvaPercent),
        (this.viewModel.codigoNcmEx = view.exCode),
        (this.viewModel.aliquotaNacional = view.nationalAliq),
        (this.viewModel.aliquotaImportacao = view.importAliq),
        (this.viewModel.cstPisEntradaId = view.pisEntrance),
        (this.viewModel.cstPisSaidaId = view.pisExit),
        (this.viewModel.cstCofinsEntradaId = view.cofinsEntrance),
        (this.viewModel.cstCofinsSaidaId = view.cofinsExit),
        (this.viewModel.aliquotaPis = view.aliqPis),
        (this.viewModel.aliquotaCofins = view.aliqCofins);
    },
    InformacoesComplementares(view) {
      //dados por estado
      if (this.viewModel.Estados == null) this.viewModel.Estados = [];
      this.viewModel.Estados[0] = {
        ufOrigemId: view.ufOrigemId,
        ufOrigem: view.ufOrigem,
        ufDestinoId: view.ufDestinoId,
        ufDestino: view.ufDestino,
        cst: view.cst,
        cstId: view.cstId,
        csosn: view.csosn,
        csosnId: view.csosnId,
        aliquotaICMS: view.icmsPercentage,
        aliquotaICMSInterna: view.internacionalIcmsPercentage,
        aliquotaMVA: view.mvaPercentage,
        aliquotaFCP: view.fcpPercentage,
        integracaoId: "string",
      };
    },
    CreateSupplier() {
      if (this.Validate()) {
        this.$http({
          url: "/ncm/novo",
          data: this.viewModel,
          method: "POST",
        })
          .then(() => {
            this.modalIsOpen = true;
            setTimeout(() => this.$router.push({ name: "ncm" }), 3000);
          })
          .catch(() => (this.modalErroSalvar = true));
      }
    },
    Validate() {
      if (
        this.viewModel.codigoNcm == null ||
        this.viewModel.codigoNcm == "" ||
        this.viewModel.descricao == null ||
        this.viewModel.descricao == ""
      ) {
        this.modalError = true;
        return false;
      }
      return true;
    },
  },
};
</script>

<style></style>
